.ant-input-number-handler-wrap {
    position: absolute;
    inset-block-start: 0;
    inset-inline-end: 0;
    width: 0px !important;
    height: 100%;
    background: #141414;
    border-start-start-radius: 0;
    border-start-end-radius: 6px;
    border-end-end-radius: 6px;
    border-end-start-radius: 0;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    transition: opacity 0.2s linear 0.2s;
}

.red{
    background-color: #ff0000;
}

.yellow{
    background-color: #e7d700;
    color: #9000bd;
}

.deleted{
    background-color: #b20000;
}

.restock{
    background-color: #c0b800;
}

.green{
    background-color: #56ad00;
}

.greenLight {
    background-color: rgba(137, 255, 0, 0.4);
}

.bluLight {
    background-color: rgba(69, 145, 255, 0.4);
}

.blu {
    animation: background-fade 5s forwards;
    font-size: 24px;
    font-weight: bold;
}

.no-blu {
    background-color: rgba(251, 250, 250, 0);
}

@keyframes background-fade {
    45.9% {
        background: #0096f3;
    }
    100% {
        background: rgba(69, 145, 255, 0.4);
    }
    50% {font-size: 40px;}
}

:where(.css-dev-only-do-not-override-uhc9p0).ant-input[disabled] {
    color: rgba(255, 255, 255, 0.88);
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #424242;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
}

.led-red {
    margin: 0 auto;
    width: 24px;
    height: 24px;
    background-color: #F00;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px;
    -webkit-animation: blinkRed 0.5s infinite;
    -moz-animation: blinkRed 0.5s infinite;
    -ms-animation: blinkRed 0.5s infinite;
    -o-animation: blinkRed 0.5s infinite;
    animation: blinkRed 0.5s infinite;
}

@-webkit-keyframes blinkRed {
    from { background-color: #F00; }
    50% { background-color: #A00; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;}
    to { background-color: #F00; }
}
@-moz-keyframes blinkRed {
    from { background-color: #F00; }
    50% { background-color: #A00; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;}
    to { background-color: #F00; }
}
@-ms-keyframes blinkRed {
    from { background-color: #F00; }
    50% { background-color: #A00; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;}
    to { background-color: #F00; }
}
@-o-keyframes blinkRed {
    from { background-color: #F00; }
    50% { background-color: #A00; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;}
    to { background-color: #F00; }
}
@keyframes blinkRed {
    from { background-color: #F00; }
    50% { background-color: #A00; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;}
    to { background-color: #F00; }
}

.led-yellow {
    margin: 0 auto;
    width: 24px;
    height: 24px;
    background-color: #FF0;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #FF0 0 2px 12px;
    -webkit-animation: blinkYellow 1s infinite;
    -moz-animation: blinkYellow 1s infinite;
    -ms-animation: blinkYellow 1s infinite;
    -o-animation: blinkYellow 1s infinite;
    animation: blinkYellow 1s infinite;
}

@-webkit-keyframes blinkYellow {
    from { background-color: #FF0; }
    50% { background-color: #AA0; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #FF0 0 2px 0; }
    to { background-color: #FF0; }
}
@-moz-keyframes blinkYellow {
    from { background-color: #FF0; }
    50% { background-color: #AA0; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #FF0 0 2px 0; }
    to { background-color: #FF0; }
}
@-ms-keyframes blinkYellow {
    from { background-color: #FF0; }
    50% { background-color: #AA0; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #FF0 0 2px 0; }
    to { background-color: #FF0; }
}
@-o-keyframes blinkYellow {
    from { background-color: #FF0; }
    50% { background-color: #AA0; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #FF0 0 2px 0; }
    to { background-color: #FF0; }
}
@keyframes blinkYellow {
    from { background-color: #FF0; }
    50% { background-color: #AA0; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #FF0 0 2px 0; }
    to { background-color: #FF0; }
}

.blink_me_red {
    margin: 0 auto;
    width: 12px;
    height: 12px;
    background-color: #F00;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px;
    -webkit-animation: blinkRed 0.5s infinite;
    -moz-animation: blinkRed 0.5s infinite;
    -ms-animation: blinkRed 0.5s infinite;
    -o-animation: blinkRed 0.5s infinite;
    animation: blinkRed 0.5s infinite;
}

.blink_me {
    margin: 0 auto;
    width: 12px;
    height: 12px;
    background-color: #56ff00;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #ffffac 0 -1px 9px, #56ff00 0 2px 12px;
    -webkit-animation: blinker 1s infinite;
    -moz-animation: blinker 1s infinite;
    -ms-animation: blinker 1s infinite;
    -o-animation: blinker 1s infinite;
    animation: blinker 1s infinite;
}

@keyframes blinker {
    from { background-color: #56ff00; }
    50% { background-color: #9d9d9d; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #ffffa3 0 -1px 9px, #56ff00 0 2px 0; }
    to { background-color: #56ff00; }
}


.checkEspositore {
    animation: blinker 1s linear infinite;
    color: red;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}


.anticon {
    color: #4cd964 !important;
}
